/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Inline Frame
// ==============================================

.iframe-wrapper {
    margin-bottom: $gap;
}

.iframe-wrapper iframe {
    width: 100%;
    height: 650px;
    border: 0;
}
