/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Toggle UI
// ==============================================

[data-toggle-trigger] {
    cursor: pointer;
}

[data-toggle-target] {
    display: none;
}

[data-toggle='show'] [data-toggle-target] {
    display: block;
}
