/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Send Friend Grid
// ==============================================

@include bp (min-width, 800px) {

    .send-friend-grid {
        &:after {
            @include clearfix;
        }
    }

    .send-friend-grid > .col-1 {
        float: left;
        width: 50%;
        padding-right: 20px;
    }

    .send-friend-grid > .col-2 {
        float: left;
        width: 50%;
        padding-left: 20px;
    }

}



// ==============================================
// Send Friend Form
// ==============================================

#send-friend-form .additional-row {
    margin-top: $margin-bottom;
}
