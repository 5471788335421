/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Address List
// ==============================================

// .address-list {}

.address-list_entry {
    margin-bottom: 0;
}

.address-list_actions {
    margin-top: 1em;
}
