/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Vars
// ==============================================

// If changed, also change JS.
// See: /skin/.../app.js
$bp-tab-accordion: 800px;



// ==============================================
// Tab Accordion
// ==============================================

.tab-accordion {
    margin-bottom: $gap;
}

// ----------------------------------------------

@include bp (max-width, $bp-tab-accordion - 1px) {

    .tab-accordion {
        overflow: hidden;
        border: 1px solid $c-tab-border;
        border-radius: 3px;
    }

}



// ==============================================
// Tab Accordion - Nav
// ==============================================

.tab-accordion_nav {
    &:after {
        @include clearfix;
    }
}

// ----------------------------------------------

@include bp (max-width, $bp-tab-accordion - 1px) {

    .tab-accordion_nav {
        display: none;
    }

}



// ==============================================
// Tab Accordion - Nav Link
// ==============================================

.tab-accordion_nav-link {
    position: relative;
    float: left;
    display: block;
    padding: 1em 1.25em;
    border-top: 1px solid $c-tab-border;
    border-right: 1px solid $c-tab-border;
    background: $c-tab-background-inactive;
    color: $c-text;
    font-size: $f-size-s;
    line-height: 1.3;
    text-align: center;
    text-decoration: none;

    &:first-child {
        border-left: 1px solid $c-tab-border;
        border-top-left-radius: 3px;
    }

    &:last-child {
        border-top-right-radius: 3px;
    }
}

.no-touchevents .tab-accordion_nav-link {
    &:hover {
        background: $c-tab-border;
        color: $c-text;
    }
}

.tab-accordion_nav-link--active {
    background: $c-tab-background-active;
    color: $c-action;
    cursor: default;

    &:first-child {
        border-top-left-radius: 0;
    }

    &:last-child {
        border-top-right-radius: 0;
    }

    // Highlight the top edge
    &:before {
        content: '';
        position: absolute;
        top: -4px;
        left: -1px; // Cover border
        width: calc(1px + 100% + 1px); // Include borders
        height: 4px;
        border-radius: 4px 4px 0 0;
        background: $c-action;
    }

    // Hide the bottom border
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #FFF;
    }
}

.no-touchevents .tab-accordion_nav-link--active {
    &:hover {
        background: $c-tab-background-active;
        color: $c-action;
    }
}



// ==============================================
// Tab Accordion - Section
// ==============================================

.tab-accordion_section {
    background: #FFF;
}

// ----------------------------------------------

@include bp (min-width, $bp-tab-accordion) {

    .tab-accordion_section {
        display: none;

    }

    .tab-accordion_section--active {
        display: block;
        padding: 20px;
        border: 1px solid $c-tab-border;
        border-radius: 0 0 3px 3px;
    }

}



// ==============================================
// Tab Accordion - Title
// ==============================================

@include bp (max-width, $bp-tab-accordion - 1px) {

    .tab-accordion_title {
        position: relative;
        margin-bottom: 0;
        padding: 15px $liner 15px ($liner + 11px + 10px);
        border-bottom: 1px solid $c-tab-border;
        background: $c-module-background;
        font-size: $f-size-l;
        cursor: pointer;

        // Arrow
        &:before {
            @include svg (arrow-11-right-dark);
            content: '';
            position: absolute;
            top: 50%;
            left: $liner;
            width: 11px;
            height: 11px;
            margin-top: (11px / -2);
            transition: transform 150ms linear;
        }
    }

    .no-touchevents .tab-accordion_title {
        &:hover {
            background: $c-tab-border;
        }
    }

    .tab-accordion_section:first-child .tab-accordion_title {
        border-top: 1px solid $c-tab-border;
    }

    .tab-accordion_section:last-child:not(.tab-accordion_section--active) .tab-accordion_title {
        border-bottom: 0;
    }

    .tab-accordion_section--active .tab-accordion_title {
        // Arrow
        &:before {
            transform: rotate(90deg);
        }
    }

}



// ==============================================
// Tab Accordion - Body
// ==============================================

.tab-accordion_body > *:last-child {
    margin-bottom: 0;
}

// ----------------------------------------------

@include bp (max-width, $bp-tab-accordion - 1px) {

    .tab-accordion_body {
        display: none;
    }

    .tab-accordion_section--active .tab-accordion_body {
        display: block;
        padding: $liner;
        border-bottom: 1px solid $c-tab-border;
    }

    .tab-accordion_section--active:last-child .tab-accordion_body {
        border-bottom: 0;
    }

}
