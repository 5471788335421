/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Tabs
// ==============================================

.tabs {
    margin-bottom: $gap;
}



// ==============================================
// Tabs - Nav
// ==============================================

.tabs_nav {
    &:after {
        @include clearfix;
    }
}



// ==============================================
// Tabs - Nav Link
// ==============================================

.tabs_nav-link {
    position: relative;
    display: block;
    float: left;
    text-decoration: none;
}



// ==============================================
// Tabs - Section
// ==============================================

.tabs_section {
    display: none;
}

.tabs_section--active {
    display: block;
}

.tabs_section > *:last-child {
    margin-bottom: 0;
}
