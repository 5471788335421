/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Typography - Default
// ==============================================

body,
button,
input,
table,
textarea {
    font-family: $f-stack-sans;
    color: $c-text;
    font-size: $f-size;
    line-height: $line-height;
}


// ----------------------------------------------
// Anchor

a {
    color: $c-link;
    text-decoration: none;

    &:hover {
        color: $c-link-hover;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
        //color: $c-link-focus;
        //color: inherit;
    }

    &:active {
        //color: $c-link-active;
        color: inherit;
    }
}


// ----------------------------------------------
// Address

address {
    margin-bottom: 10px;
}


// ----------------------------------------------
// Blockquote

blockquote {
    margin-bottom: $gap;
    font-family: $f-stack-special;
    font-size: 32px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.3;

    &:before {
        content: '\201C';
    }

    &:after {
        content: '\201D';
    }
}


// ----------------------------------------------
// Heading

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
}

h1, .h1 { @include h1; }
h2, .h2 { @include h2; }
h3, .h3 { @include h3; }
h4, .h4 { @include h4; }
h5, .h5 { @include h5; }
h6, .h6 { @include h6; }

// ----------------------------------------------

@media print {

    h1, .h1 { font-size: 24px !important; }
    h2, .h2 { font-size: 18px !important; }
    h3, .h3 { font-size: 14px !important; }
    h4, .h4 { font-size: 12px !important; }

}


// ----------------------------------------------
// Ordered + Unordered List

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}


// ----------------------------------------------
// Paragraph

p {
    margin-bottom: $margin-bottom;
    line-height: $line-height;
}
