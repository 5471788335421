/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Rating Table
// ==============================================

.rating-table {
    margin-top: 15px;
    margin-bottom: 15px;
}

.rating-table th {
    padding-right: 1em;
    font-weight: normal;
    font-style: italic;
}
