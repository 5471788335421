/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Button Group
// ==============================================

.button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-right: -1rem;
}

.button-group .button {
    margin: 1rem 1rem 0 0;
}



// ==============================================
// Button Group - Label
// ==============================================

.button-group_label {
    margin: 1rem 1rem 0 0;
    white-space: nowrap;
}
