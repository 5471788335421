/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Card List
// ==============================================

.card-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.card-list_item {
    display: flex;
    width: 100%;
    padding: 0 15px 30px;
}

.card-list_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    padding: 15px;
    border: 1px solid $c-module-border;
    border-radius: $border-radius;
    background: $c-module-background;
}

.card-list_card > *:last-child {
    margin-bottom: 0;
}

.card-list .button-group {
    margin-top: auto;
}

// ----------------------------------------------

@include bp (min-width, 500px) {

    .card-list_item {
        width: 50%;
    }

}

// ----------------------------------------------

@media print {

    .card-list_card {
        padding: (15px / 2);
    }

}




// ==============================================
// Modifier: Three
// ==============================================

@include bp (min-width, 800px) {

    .card-list--three .card-list_item {
        width: 33.33%;
    }

}



// ==============================================
// Modifier: Four
// ==============================================

@include bp (min-width, 800px) {

    .card-list--four .card-list_item {
        width: 33.33%;
    }

}

// ----------------------------------------------

@include bp (min-width, 1100px) {

    .card-list--four .card-list_item {
        width: 25%;
    }

}
