/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Tag List
// ==============================================

.tag-list {
    overflow: hidden;
    margin-top: -5px;
    margin-bottom: $margin-bottom;
    margin-left: -5px;
}

.tag-list_item {
    float: left;
    margin-top: 5px;
    margin-left: 5px;
}

.tag-list_button {
    text-transform: none;
}

//.tag-list_count {}
