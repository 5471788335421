/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Featured Product
// ==============================================

.featured-product {
    margin-bottom: $gap;
}

.featured-product .product-name {
    margin-bottom: 1em;
}
