/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Poster Blurb
// ==============================================

.poster-blurb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $gap;
}

// ---------------------------------------------

@include bp (min-width, $bp-poster-blurb) {

    .poster-blurb {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

}



// ==============================================
// Poster Blurb - Item
// ==============================================

.poster-blurb_item {
    max-width: 655px;
}

// ----------------------------------------------

@include bp (max-width, $bp-poster-blurb - 1px) {

    .poster-blurb_item {
        // Contains the textual content
        &:last-child {
            margin-top: $gutter;
        }
    }

}

// ----------------------------------------------

@include bp (min-width, $bp-poster-blurb) {

    .poster-blurb_item {
        max-width: (655px + $liner);
    }

    .poster-blurb:not(.poster-blurb--flip) .poster-blurb_item {
        &:first-child {
            padding-right: $liner;
        }

        &:last-child {
            padding-left: $liner;
        }
    }

}



// ==============================================
// Poster Blurb - Misc.
// ==============================================

// ----------------------------------------------

@include bp (min-width, $bp-poster-blurb) {

    .poster-blurb h2 {
        font-size: 28px;
    }

}



// ==============================================
// Modifier: Flip
// ==============================================

@include bp (min-width, $bp-poster-blurb) {

    .poster-blurb--flip {
        flex-direction: row-reverse;
    }

    .poster-blurb--flip .poster-blurb_item {
        &:first-child {
            padding-left: $liner;
        }

        &:last-child {
            padding-right: $liner;
        }
    }

}
