/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Pager
// ==============================================

//.pager {}

.pages {
    overflow: hidden;
    margin-bottom: 10px;
}

.pages li {
    float: left;
    margin: 0 8px 8px 0;
    font-size: $f-size-s;
    text-align: center;
}

.pages a,
.pages .current {
    @extend .button;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
}

.pages a {
    text-decoration: none;
}


// ----------------------------------------------
// Current

.pages .current {
    background: transparent;
    box-shadow: inset 0 0 0 1px $c-slate;
    color: $c-text;

    &:hover {
        background: transparent;
        color: $c-text;
        cursor: default;
    }
}



// ----------------------------------------------
// Next + Previous

.pages .next,
.pages .previous {
    width: auto;
    padding: 0 10px;
}
