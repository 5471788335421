/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// HR (Horizontal Rule)
// ==============================================

hr {
    margin-bottom: $gap;
    border: 0;
    border-top: 1px solid $c-hr;
}


// ----------------------------------------------
// Modifier: Dash

.hr--dash {
    border-style: dashed;
}


// ----------------------------------------------
// Modifier: Subtle

.hr--subtle {
    border-color: $c-hr-subtle;
}


// ----------------------------------------------
// Modifier: Thick

.hr--thick {
    border-top-width: 5px;
}
