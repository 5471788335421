/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Payment Details List
// ==============================================

//.payment-details-list {}

//.payment-details-list dt {}

.payment-details-list dd {
    padding-bottom: 10px;
}
