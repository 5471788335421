/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Stat
// ==============================================

.stat-label {
    font-size: $f-size-xxs;
    text-transform: uppercase;
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
}
