/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Matter
// ==============================================

.site-matter {
    padding-top: $trim-mid;
}

.catalog-category-view .site-matter,
.catalogsearch-result-index .site-matter {
    padding-top: $trim;
}

// ----------------------------------------------

@include bp (min-width, 900px) {

    .site-matter {
        padding-top: $trim-wide;
    }

}
