/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Mixin - Menu
// ==============================================

@mixin menu {
    position: absolute;
    z-index: $z-menu;
    border-radius: $border-radius;
    background: $c-module-background;
    box-shadow:
        0 0 0 1px black(0.15),
        0 2px 7px black(0.35);
}
