/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Table
// ==============================================

.table {
    width: 100%;
    margin-bottom: $gap;
}

.table td,
.table th {
    padding: 10px 8px;
    border-top: 1px solid $c-table-border;
    border-bottom: 1px solid $c-table-border;
    vertical-align: top;
}

.table th {
    font-weight: bold;
}

.table thead th {
    padding-top: 8px;
    padding-bottom: 8px;
    background: $c-table-background;
    font-size: $f-size-s;
    white-space: nowrap;
}

.table tfoot tr {
    background: $c-table-background;
}

// ----------------------------------------------

@media print {

    .table td,
    .table th {
        padding: 4px;
    }

}



// ==============================================
// Modifier: Border
// ==============================================

.table--border {
    border: 1px solid $c-table-border;
}

.table--border th,
.table--border td {
    border: 1px solid $c-table-border;
}



// ==============================================
// Modifier: Hover
// ==============================================

.table--hover tbody tr:hover td {
    background: $c-table-hover;
}



// ==============================================
// Modifier: Linearize
// ==============================================

@include bp (max-width, $bp-table-linearize - 1px) {

    .table--linearize {
        border: 0;
    }

    .table--linearize tr,
    .table--linearize th,
    .table--linearize td {
        display: block;
    }

    .table--linearize th,
    .table--linearize td {
        border: 0;
    }


    // ----------------------------------------------
    // Head

    .table--linearize thead {
        display: none;
    }


    // ----------------------------------------------
    // Body - TR

    .table--linearize tbody tr {
        border-top: 1px solid $c-table-border;
        padding: 12px 0;

        &:last-child {
            border-bottom: 1px solid $c-table-border;
        }
    }


    // ----------------------------------------------
    // Body - TD

    .table--linearize tbody td {
        padding: 0;
        border: 0;
    }


    // ----------------------------------------------
    // Foot

    .table--linearize tfoot tr {
        border-bottom: 1px solid $c-table-border;
    }

}



// ==============================================
// Modifier: Linearize Utilities
// ==============================================

// These utility classes are designed for hiding table rows and showing
// other elements when a table is linearized for small screens. Typically
// the first cell remains visible and following cells are hidden with
// their content duplicated by JS into the first cell and shortened).

// ----------------------------------------------

@include bp (max-width, $bp-table-linearize - 1px) {

    .is-linearized-hide-cell,
    .is-linearized-hide-elem {
        display: none !important;
    }

    .is-linearized-show-cell {
        display: table-cell !important;
    }

    .is-linearized-show-elem {
        display: block !important;
    }

    .is-linearized-unpad {
        padding: 0 !important;
    }

}

// ----------------------------------------------

@include bp (min-width, $bp-table-linearize) {

    .is-linearized-hide-cell {
        display: table-cell !important;
    }

    .is-linearized-hide-elem {
        display: block !important;
    }

    .is-linearized-show-cell,
    .is-linearized-show-elem {
        display: none !important;
    }

}



// ==============================================
// Modifier: Zebra Dark
// ==============================================

.table--zebra-dark tbody tr:nth-child(odd) {
    background: $c-table-zebra-dark;
}

.table--zebra-dark tbody tr:nth-child(even) {
    background: $c-table-zebra-light;
}



// ==============================================
// Modifier: Zebra Light
// ==============================================

.table--zebra-light tbody tr:nth-child(odd) {
    background: $c-table-zebra-light;
}

.table--zebra-light tbody tr:nth-child(even) {
    background: $c-table-zebra-dark;
}
