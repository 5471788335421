/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Item Options List
// ==============================================

.item-options-list {
    margin-top: 0.5em;
    font-size: $f-size-s;
}

.item-options-list .price {
    padding-left: 0.5em;
    color: $c-text-subtle;
}
