/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Global Layout
// ==============================================

body {
    background: $c-site-background;
    -webkit-overflow-scrolling: touch; // Use inertia when scrolling (iOS turns off with "overflow-x: hidden" for some reason)
}



// ==============================================
// Site Wrap
// ==============================================

//.site-wrap {}
