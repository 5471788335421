/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Dashboard
// ==============================================

.dashboard {
    margin-bottom: $gap;
}

.dashboard_section {
    margin-bottom: ($gap * 1.5);
}



// ==============================================
// Dashboard Nav
// ==============================================

/*
.dashboard-nav {
    margin-bottom: $gap;
    padding: 5px 0;
    border: 1px solid $c-module-border;
    border-radius: $border-radius;
    background: $c-module-background;
}

.dashboard-nav li.current {
    background: $c-module-background;
}

.dashboard-nav a {
    display: block;
    padding: 6px 10px;
    color: $c-text;
    font-size: $f-size-s;
    line-height: 1.4;
    text-decoration: none;

    &:hover {
        background: $c-action;
    }
}
*/



// ==============================================
// Customer - Address - Book
// ==============================================



// ==============================================
// Customer - Address - Edit
// ==============================================



// ==============================================
// Customer - Form -  Edit
// ==============================================



// ==============================================
// Customer - Form - Forgot Password
// ==============================================



// ==============================================
// Customer - Form - Register
// ==============================================



// ==============================================
// Customer - Form - Reset Password
// ==============================================



// ==============================================
// Customer - Logout
// ==============================================
