/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author     Brendan Falkowski
* @package    gravdept_acumen
* @copyright  2010-2016 Gravity Department. All rights reserved.
* @version    2.0.0
*/


// ==============================================
// Tab Carousel
// ==============================================

.tab-carousel {
    position: relative;
    margin-bottom: $gap;
}

.tab-carousel_link {
    display: block;
}

.tab-carousel .owl-item.loading {
    min-height: 150px;
    background: url(../img/loader.svg) no-repeat center center;
    background-size: 48px 48px;
    animation: rotate-cw 1s linear;
    animation-iteration-count: infinite;
}



// ==============================================
// Tab Carousel - Owl Buttons
// ==============================================

.tab-carousel .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: (44px / -2); // Height of button only
}

.tab-carousel .owl-next,
.tab-carousel .owl-prev {
    @include image-replacement;
    position: absolute;
    width: 44px;
    height: 44px;
}

.tab-carousel .owl-next {
    right: (-$trim - 1px);
}

.tab-carousel .owl-prev {
    left: (-$trim - 1px);

    // Arrow icon
    &:before {
        transform: rotate(180deg);
    }
}

// ----------------------------------------------

@include bp (min-width, 768px) {

    .tab-carousel .owl-nav {
        margin-top: ((44px / -2) + (60px / -2)); // Height of button + pagination
    }

}

// ----------------------------------------------

@include bp (min-width, 900px) {

    .tab-carousel .owl-next {
        right: -12px;
    }

    .tab-carousel .owl-prev {
        left: -12px;
    }

}



// ==============================================
// Pagination
// ==============================================

.tab-carousel .owl-pagination {
    display: flex;
}

.tab-carousel .owl-page {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0.8em 1em;
    border-left: 1px solid $c-button-active;
    background: $c-button;
    color: #FFF;
    text-align: center;
    cursor: pointer;

    &:hover {
        background: $c-button-hover;
    }

    &:first-child {
        border-left-width: 0;
    }

    &.active {
        background: $c-action;
        cursor: default;

        &:before {
            @include triangle (up, 12px, $c-action);
            top: -12px;
            left: 50%;
            margin-left: -12px;
        }
    }
}

[data-tab-carousel-count='3'] .owl-page {
    width: 33.333%;
}

[data-tab-carousel-count='4'] .owl-page {
    width: 25%;
}

// ----------------------------------------------

@include bp (max-width, 767px) {

    .tab-carousel .owl-pagination {
        display: none;
    }

}

// ----------------------------------------------

@include bp (min-width, 1024px) {

    .tab-carousel .owl-page {
        font-size: $f-size;
    }

}
